import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../../components/layout/Layout.component";
import Author from "../../../components/author/Author.component";
import * as style from "../Project.style.module.scss";

const MojeMiasto = () => {
  return (
    <Layout>
      <StaticImage
        src="./images/markus-spiske-1LLh8k2_YFk-unsplash.jpg"
        as="header"
        className={style.hero}
      />
      <main className={style.post}>
        <article className={style.article}>
          <div className={style.date}>Project</div>
          <h1>restaurant moje miasto</h1>
          <h2>Introduction</h2>
          <p>
            I like this project because of the freedom of approach to content
            placement. In this case, I was given the task of creating a
            restaurant website. I could go with boring schemes or bet on
            something completely different. I wanted to show food and freedom.
          </p>
          <h2>Sketch</h2>
          <StaticImage src="./images/moje_miasto.png" as="div" />
          <h2>Result</h2>
          <StaticImage src="./images/screenshot_moje_miasto.png" as="div" />
        </article>
        <aside>
          <Author />
        </aside>
      </main>
    </Layout>
  );
};
export default MojeMiasto;

export function Head() {
  return <title>Restaurant Moje Miasto</title>;
}
